import styles from "../aggrid.module.scss";
import { Button } from "@progress/kendo-react-buttons";
import { useCallback, useRef } from "react";
import { useBooleanState } from "../../../core/tools/Hooks";
import { Input } from "@progress/kendo-react-inputs";

interface IProps {
  id: string;
  title: string;
  isSelected: boolean;

  deleteView(id: string): void;

  apply(id: string): void;

  edit(id: string, title: string): void;
}

export default function ViewRow(props: IProps) {
  const { id, title, apply, edit, deleteView, isSelected } = props;
  const isEditMode = useBooleanState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const saveTitle = useCallback(() => {
    const value = inputRef.current?.value || "";
    if (value) edit(id, value);
    isEditMode.setFalse();
  }, [edit]);

  return (
    <div className={`${styles.Views} ${styles.AdditionalVew}`}>
      {isEditMode.value ? (
        <Input ref={inputRef} defaultValue={title} onBlur={saveTitle} />
      ) : (
        <Button
          key={"apply" + id}
          fillMode={"flat"}
          onClick={() => apply(id)}
          themeColor={isSelected ? "success" : undefined}
        >
          {title}
        </Button>
      )}
      <div style={{ flex: 1 }}></div>
      {isEditMode.value ? (
        <Button
          key={"save" + id}
          fillMode={"flat"}
          onClick={saveTitle}
          iconClass={"mdi mdi-content-save-outline"}
        ></Button>
      ) : (
        <Button
          key={"edit" + id}
          fillMode={"flat"}
          onClick={isEditMode.setTrue}
          iconClass={"mdi mdi-pencil-outline"}
        ></Button>
      )}
      <Button
        key={"delete" + id}
        fillMode={"flat"}
        onClick={() => deleteView(id)}
        iconClass={"mdi mdi-delete-outline"}
      ></Button>
    </div>
  );
}
